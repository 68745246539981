<script setup lang="ts">
import type { BrandPage } from '@eo/graphql-types'

const GET_ITEM_LIST = gql`
  query Query {
    brandPages {
      slug
      title
      seoAllow
      hero {
        cdnFiles {
          url
        }
      }
    }
  }
`

const { data, error } = await useAsyncQuery(`getItemList`, GET_ITEM_LIST, {})

const pages = computed(() =>
  data.value?.brandPages?.reduce((filtered: object[], page: BrandPage) => {
    if (page.seoAllow) {
      filtered.push({
        img: page.hero?.[0]?.cdnFiles?.[0]?.url,
        slug: page.slug,
        title: page.title?.split('-')[0]?.trim(),
        content: page.title?.split('-')[1]?.trim() ?? '',
      })
    }

    return filtered
  }, []),
)
</script>
<template>
  <div class="grid auto-rows-fr gap-4 md:grid-cols-2 lg:grid-cols-3">
    <EoCard
      v-for="item in pages"
      :key="item.title"
      :label="item.title"
      media-padded
      media-position="left"
      :to="`/${item.slug}`"
    >
      <template #media>
        <EoImg
          :src="item.img"
          v-if="item.img"
        />
      </template>
      <EoCardTitle>{{ item.title }}</EoCardTitle>
      <EoCardText> {{ item.content }} </EoCardText>
    </EoCard>
  </div>
</template>
