<script setup>
const error = useError()
const handleError = () => {
  clearError({
    redirect: '/',
  })
}
</script>

<template>
  <div>
    <NuxtLayout name="default">
      <div
        class="my-md-32 mx-auto my-10 grid max-w-3xl px-5 md:justify-items-center md:text-center"
      >
        <div v-if="error?.statusCode == '404'">
          <h1 class="mb-4">Oeps! De pagina is niet gevonden.</h1>
          <p class="text-xl font-bold">
            Helaas kon deze pagina niet gevonden worden. We helpen je graag naar
            de juiste pagina voor jou.
          </p>
        </div>
        <div v-else>
          <h1 class="mb-4">Foutcode {{ error?.statusCode ?? 'onbekend' }}.</h1>
          <p class="text-xl font-bold">
            Oeps, er heeft aan onze kant een fout opgetreden!
          </p>
        </div>
        <div>
          <EoButton
            label="Ga"
            class="mt-10 text-left !shadow-none"
            @click="handleError"
            >Ga naar de homepage</EoButton
          >
        </div>
      </div>

      <NuxtErrorBoundary>
        <ErrorList class="mx-auto mb-20 grid max-w-7xl px-5" />
      </NuxtErrorBoundary>
    </NuxtLayout>
  </div>
</template>
