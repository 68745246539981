<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
<style>
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-display;
}
body {
  @apply font-sans;
}
h1 {
  @apply pt-6 text-3xl;
}
h2 {
  @apply text-3xl;
}
h4 {
  @apply text-2xl;
}
p {
  @apply block text-base;
}
ul {
  @apply list-disc pl-5 pt-5;
}
li {
  @apply py-1;
}
a {
  @apply text-purple-800 underline;
}
</style>
