
export default {
  outer: `
    relative
    mb-5
    data-[disabled]:select-none
    data-[disabled]:opacity-50
    data-[disabled]:pointer-events-none
  `,
  label: `
    hidden
  `,
  input: `
    z-0
    bg-white
    appearance-none
    min-h-full
    w-full
    h-14
    border-none
    text-lg
    text-gray-700
    p-4
    placeholder-gray-400
    flex
    outline-none

  `,
  inner: `
    flex max-w-md justify-center items-center rounded-md mb-1 overflow-hidden focus-within:border-blue-500 bg-white
    formkit-invalid:border-red-500
    formkit-invalid:border
    formkit-complete:border
    formkit-complete:border-green-600
    formkit-complete:after:content-['✓']
    formkit-complete:after:block
    formkit-complete:after:p-1
    formkit-complete:after:pr-4
    formkit-complete:after:text-green-600
  `,
  help: 'text-xs text-gray-500',
  messages: 'py-1',
  message: 'text-red-500 text-sm overflow-auto list-none',
};
