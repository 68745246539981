export default {
  input: `$reset w-4 h-4 accent-black`,
  label: '$reset',
  inner: `
  bg-transparent
  formkit-invalid:border-0
   formkit-complete:border-0
   formkit-complete:after:content-['']
   formkit-complete:after:hidden   
  `,
}
