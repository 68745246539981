import { genesisIcons } from '@formkit/icons'
import { nl } from '@formkit/i18n'
import { defineFormKitConfig } from '@formkit/vue'
import { createProPlugin, inputs } from '@formkit/pro'
import theme from './theme'
import { rootClasses } from '@formkit/theme-creator'
export default defineFormKitConfig(() => {
  const config = useRuntimeConfig()
  const pro = createProPlugin(config.public.formkitKey, inputs)

  return {
    config: {
      rootClasses: rootClasses(theme().tailwind()),
      classes: {
        messages: 'pl-0',
      },
    },
    plugins: [pro],
    icons: { ...genesisIcons },
    locales: { nl },
    locale: 'nl',
    messages: {
      nl: {
        ui: {
          incomplete:
            'Let op: Niet alle velden zijn correct ingevuld. Controleer het formulier.',
        },
      },
    },
  }
})
