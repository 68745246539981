export default {
  // autocomplete: https://formkit.com/inputs/autocomplete#sections
  // dropdown: https://formkit.com/inputs/dropdown#sections
  // taglist: https://formkit.com/inputs/taglist#sections
  outer: ``,
  wrapper: ``,
  label: ``,
  inner: `
    overflow-visible
    relative
    flex
    items-center
    focus-within:ring-2
    $radius
    ${
      "" /** === ⚠️ CAUTION: Border radius on multi-line inputs ===
    inputs such as the taglist (and the dropdown when the appearance is set to "tags") can wrap to
    multiple lines. When the input is multiline we want to clamp the maximum radius to avoid undesireable
    styling effects.
    */
    }
    p-4
    h-14
    group-data-[is-multiline]:!$radius(0,rounded-none,rounded-2xl)
    dark:border-$colorTemperature-$colorTemperatureStrengthDark
    formkit-complete:after:hidden
  `,

  input: `
    grow
    text-$scale
    text-$colorTemperature-$colorTemperatureStrength
    text-ellipsis
    min-w-0 ${"" /** allows section to shrink */}
    outline-none
    bg-transparent
    group-data-[prefix-icon]:!pl-0
    group-data-[suffix-icon]:!pr-0
    ${
      "" /** === ⚠️ CAUTION: Tailwind Forms Overrides ===
    These are overrides for the Tailwind Forms plugin. You should include them in case
    your theme user has the plugin enabled so that its styles don't conflict with your theme.
    */
    }
    border-none
    focus:ring-0

    dark:text-$colorTemperature-$colorTemperatureStrengthDark
  `,
  selectionWrapper: ` ${
    "" /* autocomplete (selection-appearance: option), dropdown (single) */
  }
    grow
    flex
    items-center
    text-$colorTemperature-$colorTemperatureStrength

    dark:text-$colorTemperature-$colorTemperatureStrengthDark
  `,
  selection: ` ${
    "" /* autocomplete (selection-appearance: option), dropdown (single) */
  }
    grow
    text-$colorTemperature-$colorTemperatureStrength
    group-data-[multiple]:p-$spacing
    dark:text-$colorTemperature-$colorTemperatureStrengthDark
  `,

  listboxButton: ` ${"" /* autocomplete, taglist */}
    w-[2.5em]
    self-stretch
    text-$scale
    flex
    items-center
    text-$colorTemperature-$colorTemperatureStrength
    z-10

    dark:text-$colorTemperature-$colorTemperatureStrengthDark
  `,
  removeSelection: `
    ${
      "" /** === ⚠️ CAUTION: shared section, different treatments ===
    The dropdown, autocomplete, and taglist all have a remove selection section.
    the styles here handle the standard dropdown and autocomplete option appearance,
    but the dropdown and taglist require special consideration when their appearence
    is rendering tags.
    */
    }
    w-[2.5em]
    self-stretch
    text-$scale
    flex
    items-center
    text-$colorTemperature-$colorTemperatureStrength
    z-10

    dark:text-$colorTemperature-$colorTemperatureStrengthDark

    ${
      "" /** === ⚠️ CAUTION: Styling the tag close button ===
      The tag close button shares a section name (removeSelection) which is already styled
      above for a single-option selection. here we need to override these styles to handle the
      removeSelection section within the context of a tag.
      */
    }
    group-[]/tag:absolute
    group-[]/tag:top-1/2
    group-[]/tag:right-[0.5em]
    group-[]/tag:-translate-y-1/2
    group-[]/tag:!w-[0.5em]
    group-[]/tag:aspect-square
    group-[]/tag:!text-inherit
  `,
  selectIcon: `
    text-$scale
    inline-flex
    justify-center
    w-[2.5em]
    relative
    my-auto
    [&>svg]:w-[1em]
    [&>svg]:mx-auto
  `,
  closeIcon: `
    text-$scale
    w-[0.75em]
    relative
    m-auto
  `,
  loaderIcon: `
   hidden
  `,
  dropdownWrapper: `
    $radius(0,rounded-none,rounded-2xl) ${
      "" /** don't allow dropdown listbox to go full rounded */
    }
  `,
  listbox: `
    bg-white
    $radius(0,rounded-none,rounded-2xl) ${
      "" /** don't allow dropdown to go full rounded */
    }
    overflow-clip
    border
    border-$colorTemperature-$colorTemperatureStrength
    empty:hidden
    dark:bg-$colorTemperature-900
    dark:border-$colorTemperature-$colorTemperatureStrengthDark
  `,
  listitem: `
  cursor-pointer
    relative
    flex
    items-center
    px-$spacing
    py-$spacing(-1)
    first:pt-$spacing
    last:pb-$spacing
    text-$colorTemperature-$colorTemperatureStrength
    text-$scale
    aria-selected:!bg-$accentColor-100
    data-[is-active]:bg-$accentColor-100
    ${"" /* keyboard focus on active items */}
    before:content-['']
    before:absolute
    before:inset-0
    data-[is-active]:aria-selected:before:ring-2
    data-[is-active]:aria-selected:before:ring-inset
    data-[is-active]:aria-selected:before:ring-$accentColor-300
    dark:text-$colorTemperature-$colorTemperatureStrengthDark
    dark:aria-selected:!bg-$accentColor-700
    dark:data-[is-active]:bg-$accentColor-700
    dark:data-[is-active]:aria-selected:before:ring-$accentColor-400
  `,
  selectedIcon: `
    flex
    absolute
    items-center
    text-$accentColor-600
    left-$spacing
    text-$scale
    h-[0.75em]
    w-[0.75em]
    shrink-0
    [&>svg]:w-full

    dark:text-$accentColor-400
  `,
  option: `
    ml-[1.25em]
  `,
  optionLoading: ``,
  emptyMessage: `
    flex
    items-center
    px-$spacing
    py-$spacing(-1)
    first:pt-$spacing
    last:pb-$spacing
    text-$colorTemperature-$colorTemperatureStrength
    text-$scale(-1)
    dark:text-$colorTemperature-$colorTemperatureStrengthDark
  `,
  emptyMessageInner: ``,
  help: ``,
  messages: ``,
  message: ``,
};
