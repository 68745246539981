export default {
  overlayPlaceholder: 'relative  z-50 rounded-md text-gray-400',
  months: 'grid grid-cols-6 w-full',
  month: `
    cursor-pointer
    flex items-center justify-center
    m-2 p-2 rounded-md
    bg-gray-200
    aria-selected:bg-gray-700 aria-selected:text-white
    focus:outline focus:outline-2 focus:outline-gray-700 focus:outline-offset-2 focus:bg-white focus:text-black
  `,
  // yearsHeader: 'flex items-center justify-center col-start-2 col-end-2',
  years:
    'grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-4 w-full cursor-pointer group-data-[disabled]:pointer-events-none group-data-[disabled]:cursor-default',
  year: `
    p-2
    items-center
    text-center
    rounded-md
    bg-gray-200
    border
    border-black
    list-none
    aria-selected:!bg-$accentColor-$accentColorStrength
    aria-selected:!text-white
    focus:outline-none
    focus:ring-2
   `,
  weekDays: `
   gap-4
   w-full
 `,
  weekDay:
    'items-center justify-center rounded-md font-medium p-4 cursor:pointer mb-4 lowercase',
  week: `
    gap-4
    w-full
  `,
  dayCell: `
    flex items-center justify-center
    w-[2.25em] h-[2.25em] m-1 p-2 rounded-md
    bg-gray-200
    mb-2
    cursor-pointer
    aria-selected:bg-gray-700 aria-selected:text-white
    focus:outline focus:outline-2 focus:outline-gray-700 focus:outline-offset-2 focus:bg-white focus:text-black
  `,
  monthsHeader: 'flex items-center justify-center col-start-2 col-end-2',
  panelWrapper:
    ' $reset absolute w-full group max-w-md top-[calc(100%_+_0.5em)] drop-shadow-[0_0_1.25em_rgba(0,0,0,.25)]  rounded-md p-5 bg-white z-10',
  panelHeader:
    'grid grid-cols-[2.5em_1fr_2.5em] justify-center items-center border-b-2 mb-4 pb-4',
  inner: '$reset relative rounded-md bg-white max-w-md flex',
  panel: 'justify-center items-center flex bg-white',
  panelClose: 'hidden',
  input:
    'bg-white appearance-none min-h-full w-full h-14 border-none text-lg text-gray-700 p-4 placeholder-gray-400 flex outline-none',
  help: 'text-xs text-gray-500',
  messages: 'py-1',
  message: 'text-red-500 text-sm',
  daysHeader: 'flex items-center justify-center',
  prev: 'mr-auto px-3 py-1 hover:bg-gray-100 hover:rounded-lg col-start-1 col-end-1',
  prevLabel: 'hidden',
  prevIcon: 'flex w-3 select-none [&>svg]:w-full',
  dayButton:
    'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-gray-700',
  monthButton:
    'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-gray-700',
  yearButton:
    'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-gray-700',
  next: 'ml-auto px-3 py-1 hover:bg-gray-100 hover:rounded col-start-3 col-end-3',
  nextLabel: 'hidden',
  nextIcon: 'flex w-3 select-none [&>svg]:w-full',
  openButton: `
    appearance-none border-0 bg-transparent flex p-0 self-stretch cursor-pointer
    focus-visible:outline-none focus-visible:text-white focus-visible:bg-gray-500
  `,
  calendarIcon: 'flex w-6 mr-4',
}
