
import { createTheme } from "@formkit/theme-creator";
import globals from "./globals";
// Core input imports
import checkbox from "./formkit/inputs/checkbox";
import radio from "./formkit/inputs/radio"
// Pro input imports
import familyDropdown from "./formkit/families/dropdown"
import dropdown from "./formkit/inputs/pro/dropdown";
import datepicker from "./formkit/inputs/pro/datepicker"

// Addon input imports
/**
 * Welcome to the FormKit starter theme.
 *
 * If this is your first time writing a FormKit theme then we strongly encourage
 * you to read all the comments in this file (and imported files) before you begin making edits.
 * Once you are comfortable feel free to remove the comments and start building.
 *
 * There are numerous comments throughout this file that will help you understand
 * how to best leverage the available features of FormKit themes. There are also comments
 * about structural styles we have included in this starter that will help you avoid stepping
 * on a landmine or two as you work.
 *
 * You can also access a fully authored theme that you can use as a reference here:
 * https://github.com/formkit/theme-regenesis
 *
 * If you have any issues / questions please reach out to us on Discord:
 * https://discord.formkit.com
 */

export default createTheme({
  meta: {
    name: "EO Meer",
    description: "Form styles for the meer platform",
    authorName: "Ronald Elzen",
    authorWebsite: "https://formkit.com",
    formKitVersion: "^1.0.0",
    /**
     * === Supported Inputs ===
     * This is a list of all the inputs that are supported by this theme.
     * This information will allow users to filter themes by the inputs they support
     * as required for their own projects.
     */
    supportedInputs: [
      // Core inputs
      "button",
      "checkbox",
      "color",
      "date",
      "datetime-local",
      "email",
      "file",
      "month",
      "number",
      "password",
      "radio",
      "range",
      "search",
      "select",
      "submit",
      "summary", // error summary component for Forms https://formkit.com/inputs/form#validation-and-error-summary
      "tel",
      "text",
      "textarea",
      "time",
      "url",
      // Pro inputs
      "autocomplete",
      "colorpicker",
      "datepicker",
      "dropdown",
      "mask",
      "rating",
      "repeater",
      "slider",
      "taglist",
      "toggle",
      "togglebuttons",
      "transferlist",
      // Addon inputs
      "barcode",
      "multi-step",
    ],
    /**
     * === Light & Dark mode ===
     * Specify which color modes your theme supports so that
     * users can filter according to their project needs.
     */
    lightMode: true,
    darkMode: true,
  },
  /**
   * === Variables ===
   * You can define custom variables that can be used in your theme.
   * Each variable can define an editor, value, scale, min, and max.
   * Defined variables will appear in the control panel UI.
   *
   * A defined variable is available in your class lists via the $ prefix.
   * If your variable has a scale associated with it (most do by default) you can
   * output variables above and below the selected variable value by using the following
   * syntax:
   *
   * $variableName(-1,0,1)
   *
   * Where -1 is the distance to move across the scale, 0 is the minimum accepted value,
   * and 1 is the maximum accepted value. Your minimum and maximum values must be included
   * on your variable's scale. You can also define a wildcard value (*) to indicate that
   * any value from the smallest to the highest is acceptable.
   *
   * $variableName(-1,*)
   *
   * === Editor ===
   * The editor determines which UI control will show up in the theme control panel.
   * Available editors are:
   * - buttons
   * - color
   * - fontSize
   * - radius
   * - spacing
   * - select
   *
   * === Value ===
   * The value is the default value for the variable.
   *
   * === Scale ===
   * The scale is an array of values that can be used to set the variable. Some controls
   * come with their own associated scale (such as the radius control), so when providing a
   * scale for those controls you must provide an array of values that exists within the target scale.
   *
   * === Min & Max ===
   * The minimum and maximum values that can be set for the variable via the UI. When you reference the
   * variable in your theme with modifiers (eg. $radius(-2,*)) you can access values on the scale
   * that are beyond what is available via the editor panel UI.
   */
  variables: {
    /**
     * === Variables without UI controls ===
     * You can also define variables that have no UI controls
     * but that you may want to be able to change in a single
     * location as you work on your theme.
     *
     * To do so, simply assign a string value to the variable name.
     */
    accentColor: "neutral",
    accentColorStrength: "900",
    accentColorStrengthDark: "100",
    colorTemperature: "neutral",
    colorTemperatureStrength: "900",
    colorTemperatureStrengthDark: "100",
    inputMaxWidth: "max-w-[20em]",

    /**
     * === Variables with UI controls ===
     */
    radius: {
      editor: "radius",
      value: "rounded-none",
      /**
       * (Optional) you can provide your own limited
       * subset of a default scale to use for the editor.
       * This is helpful for scales that have a large amount
       * of variance that may be more granular than needed
       * for theming purposes.
       */
      scale: [
        "rounded-none",
        "rounded",
        "rounded-lg",
        "rounded-xl",
        "rounded-2xl",
        "rounded-full",
      ],
    },
    spacing: {
      /**
       * This variable is using the default scale — it does not provide its own.
       * It does provide a min and max value to constrain the editor UI — however,
       * variable usage by the theme author within the theme (eg. $spacing(-2,0.5,3))
       * can still access values beyond the min and max.
       */
      editor: "spacing",
      value: "2",
      min: "1",
      max: "3",
    },
    scale: {
      editor: "fontSize",
      value: "base",
      /**
       * This variable is including a custom value in its scale at the low end.
       * This is helpful for when you need to exceed the range of
       * a default scale.
       *
       * eg: the lowest value when used as `text-$scale(-3)` would output:
       * `text-[11px] [line-height:1em]`
       */
      scale: [
        "[11px] [line-height:1em]",
        "xs",
        "sm",
        "base",
        "lg",
        "xl",
        "2xl",
      ],
      /**
       * Don't forget: the min and max properties only constrain the UI editor.
       * You can still make use of the full scale in your theme as the author.
       */
      min: "sm",
      max: "lg",
    },
  },
  inputs: {
    /**
     * === Globals ===
     * These class lists apply to all inputs that have matching sections.
     * Use carefully.
     */
    __globals: globals,

    /**
     * === Family groupings ===
     * These class lists apply to all inputs that are part of the matching family.
     * There is a comment in each input class list that indicates which family it belongs to.
     */
    "family:dropdown": familyDropdown, // pro only

    /**
     * === Core Inputs ===
     * These are the core inputs that ship with the open-source version of FormKit.
     */
    checkbox: checkbox,
    radio: radio,
    /**
     * Pro inputs
     */
    dropdown: dropdown,
    datepicker: datepicker,
  },
});
