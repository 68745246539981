import { default as _91slug_93msfiAekumQMeta } from "/app/pages/[slug].vue?macro=true";
import { default as _91slug_93WZKWwru4dFMeta } from "/app/pages/abonneren/[slug].vue?macro=true";
import { default as _91slug_93QqHiICiWS4Meta } from "/app/pages/abonneren/selectie/[slug].vue?macro=true";
import { default as _91slug_93RkxuqEehsWMeta } from "/app/pages/bestel/[slug].vue?macro=true";
import { default as _91slug_93HqOU6GpuDYMeta } from "/app/pages/doneren/[slug].vue?macro=true";
import { default as _91slug_93NhOJwIJYlZMeta } from "/app/pages/gratis-online-product/[slug].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91slug_93Tp6zxbJN7lMeta } from "/app/pages/online-lezen/[magazine]/[slug].vue?macro=true";
import { default as index8FNLy0VwhoMeta } from "/app/pages/online-lezen/[magazine]/index.vue?macro=true";
import { default as _91slug_931otP6uMfVHMeta } from "/app/pages/selectie/[slug].vue?macro=true";
import { default as _91slug_93sdInPaso9eMeta } from "/app/pages/training/[slug].vue?macro=true";
import { default as component_45stubTOq3NWgWLHMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubTOq3NWgWLH } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91slug_93msfiAekumQMeta?.name ?? "slug",
    path: _91slug_93msfiAekumQMeta?.path ?? "/:slug()",
    meta: _91slug_93msfiAekumQMeta || {},
    alias: _91slug_93msfiAekumQMeta?.alias || [],
    redirect: _91slug_93msfiAekumQMeta?.redirect,
    component: () => import("/app/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WZKWwru4dFMeta?.name ?? "abonneren-slug",
    path: _91slug_93WZKWwru4dFMeta?.path ?? "/abonneren/:slug()",
    meta: _91slug_93WZKWwru4dFMeta || {},
    alias: _91slug_93WZKWwru4dFMeta?.alias || [],
    redirect: _91slug_93WZKWwru4dFMeta?.redirect,
    component: () => import("/app/pages/abonneren/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QqHiICiWS4Meta?.name ?? "abonneren-selectie-slug",
    path: _91slug_93QqHiICiWS4Meta?.path ?? "/abonneren/selectie/:slug()",
    meta: _91slug_93QqHiICiWS4Meta || {},
    alias: _91slug_93QqHiICiWS4Meta?.alias || [],
    redirect: _91slug_93QqHiICiWS4Meta?.redirect,
    component: () => import("/app/pages/abonneren/selectie/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RkxuqEehsWMeta?.name ?? "bestel-slug",
    path: _91slug_93RkxuqEehsWMeta?.path ?? "/bestel/:slug()",
    meta: _91slug_93RkxuqEehsWMeta || {},
    alias: _91slug_93RkxuqEehsWMeta?.alias || [],
    redirect: _91slug_93RkxuqEehsWMeta?.redirect,
    component: () => import("/app/pages/bestel/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HqOU6GpuDYMeta?.name ?? "doneren-slug",
    path: _91slug_93HqOU6GpuDYMeta?.path ?? "/doneren/:slug()",
    meta: _91slug_93HqOU6GpuDYMeta || {},
    alias: _91slug_93HqOU6GpuDYMeta?.alias || [],
    redirect: _91slug_93HqOU6GpuDYMeta?.redirect,
    component: () => import("/app/pages/doneren/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NhOJwIJYlZMeta?.name ?? "gratis-online-product-slug",
    path: _91slug_93NhOJwIJYlZMeta?.path ?? "/gratis-online-product/:slug()",
    meta: _91slug_93NhOJwIJYlZMeta || {},
    alias: _91slug_93NhOJwIJYlZMeta?.alias || [],
    redirect: _91slug_93NhOJwIJYlZMeta?.redirect,
    component: () => import("/app/pages/gratis-online-product/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Tp6zxbJN7lMeta?.name ?? "online-lezen-magazine-slug",
    path: _91slug_93Tp6zxbJN7lMeta?.path ?? "/online-lezen/:magazine()/:slug()",
    meta: _91slug_93Tp6zxbJN7lMeta || {},
    alias: _91slug_93Tp6zxbJN7lMeta?.alias || [],
    redirect: _91slug_93Tp6zxbJN7lMeta?.redirect,
    component: () => import("/app/pages/online-lezen/[magazine]/[slug].vue").then(m => m.default || m)
  },
  {
    name: index8FNLy0VwhoMeta?.name ?? "online-lezen-magazine",
    path: index8FNLy0VwhoMeta?.path ?? "/online-lezen/:magazine()",
    meta: index8FNLy0VwhoMeta || {},
    alias: index8FNLy0VwhoMeta?.alias || [],
    redirect: index8FNLy0VwhoMeta?.redirect,
    component: () => import("/app/pages/online-lezen/[magazine]/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_931otP6uMfVHMeta?.name ?? "selectie-slug",
    path: _91slug_931otP6uMfVHMeta?.path ?? "/selectie/:slug()",
    meta: _91slug_931otP6uMfVHMeta || {},
    alias: _91slug_931otP6uMfVHMeta?.alias || [],
    redirect: _91slug_931otP6uMfVHMeta?.redirect,
    component: () => import("/app/pages/selectie/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sdInPaso9eMeta?.name ?? "training-slug",
    path: _91slug_93sdInPaso9eMeta?.path ?? "/training/:slug()",
    meta: _91slug_93sdInPaso9eMeta || {},
    alias: _91slug_93sdInPaso9eMeta?.alias || [],
    redirect: _91slug_93sdInPaso9eMeta?.redirect,
    component: () => import("/app/pages/training/[slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubTOq3NWgWLHMeta?.name ?? undefined,
    path: component_45stubTOq3NWgWLHMeta?.path ?? "/",
    meta: component_45stubTOq3NWgWLHMeta || {},
    alias: component_45stubTOq3NWgWLHMeta?.alias || [],
    redirect: component_45stubTOq3NWgWLHMeta?.redirect,
    component: component_45stubTOq3NWgWLH
  }
]